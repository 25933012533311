import { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { useMenuUsuario } from 'client/hooks';
import { setMenus } from 'modules/auth/reducer/auth';
import { MainLayout } from 'modules/main/components';
import { useAppDispatch } from 'store';
import { lazyImport } from 'utils/lazyImport';
import EditMapForm from 'modules/mapa/pages/EditMapa/EditMapForm';
import { AddGroupClients } from 'modules/groupClients/pages/AddGroupClients';
import { ListGroupClients } from 'modules/groupClients/pages/ListGroupClients';
import { ListGroupClientMembers } from 'modules/groupClients/pages/GroupClientMembers/ListGroupClientMembers';
import { ResearchDataAnalysis } from 'modules/formulario/pages/ResearchDataAnalysis';
import {
  AcompanhamentoDiario,
  AnaliseVisitas,
  CadastroMapa,
  CadastroMapaForm,
  CadastroPonto,
  ControleVisitas,
  Regioes,
  Roteirizador,
} from 'modules/mapa/pages';
import { AgendaFeriados } from 'modules/cadastro/pages';
import { DashboardWrapper } from 'modules/main/pages';
import { CalendarRoterizador } from 'components/FullCalendar';
import { NovoPedido, Pedido } from 'modules/pedido/pages';
import { EditUserProfile } from 'modules/usuario/pages/EditUserProfile';
import { AddCompany } from 'modules/companies/AddCompany';
import { EditCompany } from 'modules/companies/EditCompany';
import {
  Relatorios7000List,
  Relatorios7000Designer,
  Relatorios7000New,
  Relatorios7000Player,
  Relatorios7000Edit,
} from 'modules/relatorios/pages';
import { SqlPage } from 'modules/vmaissql/pages';
import { PhotoMasterPage } from 'modules/controleFotoProduto/pages';
import { ParametrosPage } from 'modules/parametros/pages';
import { FormularioPage, FormularioRespostaPage } from 'modules/formulario/pages';
import { ItensMenu } from 'modules/itensMenu/pages';
import { ResetUserAccess, UsuarioItensMenu } from 'modules/acessoUsuario/pages';
import { Companies } from 'modules/companies';
import { RegraComercialPage } from 'modules/regrasComerciais/pages';
import { KpiList } from 'modules/kpi';
import KpiView from 'modules/kpi/pages/kpiView/kpiView.index';
import { KpiProvider } from 'modules/kpi/kpi.context';
import { VerbasList } from 'modules/verbas';
import { VerbasProvider } from 'modules/verbas/verbas.context';
import { TesteMainBox } from 'modules/teste';

const { FormularioRespostaItemsPage } = lazyImport(
  async () => await import('modules/formulario/pages'),
  'FormularioRespostaItemsPage',
);

const PrivateRoutes = () => {
  const dispatch = useAppDispatch();
  const { data } = useMenuUsuario();

  useEffect(() => {
    if (data) {
      dispatch(setMenus(data));
    }
  }, [data]);

  return (
      <Routes>
        <Route element={<MainLayout />}>
          <Route path="dashboard" element={<DashboardWrapper />} />
          <Route path="auth/*" element={<Navigate to="/dashboard" />} />

          <Route path="sql" element={<SqlPage />} />

          <Route path="foto-produto" element={<PhotoMasterPage />} />

          <Route path="parametros" element={<ParametrosPage />} />

          <Route path="cadastro/agenda-feriados" element={<AgendaFeriados />} />

          <Route path="formulario" element={<FormularioPage />} />
          <Route path="respostas" element={<FormularioRespostaPage />} />
          <Route path="respostas/:id" element={<FormularioRespostaItemsPage />} />

          <Route path="itens-menu" element={<ItensMenu />} />

          <Route path="acesso-usuarios" element={<UsuarioItensMenu />} />

          <Route path="mapa" element={<CadastroMapa />} />
          <Route path="mapa/:codEmpresa/:id" element={<EditMapForm />} />
          <Route path="mapa/novo/:codEmpresa" element={<CadastroMapaForm />} />
          <Route path="mapa/acompanhamento-diario" element={<AcompanhamentoDiario />} />

          <Route path="regioes/:codEmpresa/:codUser/:codMapa" element={<Regioes />} />

          <Route path="roteirizador/:codMapa/:codSetor/:codVendedor/:codEmpresa" element={<Roteirizador />} />
          <Route path="cadastro/ponto/:codEmpresa/:codMapa/:codVendedor" element={<CadastroPonto />} />
          <Route path="agenda-vendedor/:codEmpresa/:codVendedor/:codMapa/:codSetor" element={<CalendarRoterizador />} />

          <Route path="controle-visitas" element={<ControleVisitas />} />
          <Route path="analise-visitas" element={<AnaliseVisitas />} />

          <Route path="grupo-clientes/criar" element={<AddGroupClients />} />
          <Route path="grupo-clientes" element={<ListGroupClients />} />
          <Route path="grupo-clientes/listar/membros/:codEmpresa" element={<ListGroupClientMembers />} />

          <Route path="pedido" element={<Pedido />} />
          <Route path="pedido/novo" element={<NovoPedido />} />

          <Route path="pesquisas/analise-dados" element={<ResearchDataAnalysis />} />
          <Route path="usuario" element={<EditUserProfile />} />

          <Route path="empresas" element={<Companies />} />
          <Route path="empresas/adicionar" element={<AddCompany />} />
          <Route path="empresas/editar" element={<EditCompany />} />

          <Route path="relatorios" element={<Relatorios7000List />} />
          <Route path="relatorios/novo" element={<Relatorios7000New />} />
          <Route path="relatorios/editar/:id" element={<Relatorios7000Edit />} />
          <Route path="relatorios/:id" element={<Relatorios7000Player />} />
          <Route path="relatorios/designer" element={<Relatorios7000Designer />} />

          <Route
            path="kpi/*"
            element={
              <KpiProvider>
                <Routes>
                  <Route path="configurar" element={<KpiList />} />
                  <Route path=":id" element={<KpiView />} />
                  <Route path="" element={<KpiView />} />
                </Routes>
              </KpiProvider>
            }
          />

          <Route
            path="verbas/*"
            element={
              <VerbasProvider>
                <Routes>
                  <Route path="" element={<VerbasList />} />
                </Routes>
              </VerbasProvider>
            }
          />

          <Route path="teste" element={<TesteMainBox />} />

          <Route path="regras-comerciais/:id" element={<RegraComercialPage />} />

          <Route path="acesso-usuarios/redefinir-acesso" element={<ResetUserAccess />} />

          <Route path="*" element={<Navigate to="/dashboard" />} />
        </Route>
      </Routes>
  );
};

export { PrivateRoutes };
