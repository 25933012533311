import styled from 'styled-components';

export const RowItens = styled.div`
  display: flex;
  flex-direction: row;
`;

export const IconTable = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RowInputsData = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 15px;
`;

export const BoxInput = styled.div`
  display: flex;
  flex-direction: column;

  .p-calendar .p-inputtext {
    height: 3rem;
  }

  .p-inputtext {
    background-color: var(--gray-300);
    border: none;
  }
`;

export const HeaderBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: last baseline;
  gap: 15px;
  padding: 5px 0;
`;

export const ContainerTable = styled.div`
  width: 100%;

  @media (max-width: 992px) {
    padding: 10px;
    padding-bottom: 30px;
  }
`;

export const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: first baseline;
  text-align: left;
`;

export const ItemImageTemplateBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: first baseline;
  text-align: left;
  cursor: pointer;
`;

export const FomImageTableIcon = styled.section`
  font-size: 1.5rem;
  padding-right: 10px;
  text-align: center !important;
  svg {
    color: #606060;
    font-size: 1.5rem;
  }

  img {
    max-width: 50px;
    max-height: 50px;
    margin: 0 auto;
  }
`;

export const RestricaoTable = styled.div`
  padding: 10px;
  table thead {
    position: sticky;
    top: 5rem;
    z-index: 99;
  }
  .p-datatable-header {
    position: sticky !important;
    top: 0rem !important;
    z-index: 101 !important;
  }
`;


export const ItensTable = styled.div`
  padding: 10px;
  table thead {
    position: sticky;
    top: 5rem;
    z-index: 99;
  }
  .p-datatable-header {
    position: sticky !important;
    top: 0rem !important;
    z-index: 101 !important;
  }
`;