import { IRouterData, IToProcessData, IProcessedDataReturn } from 'client/interfaces';
import {
  dataToAlterProps,
  dataToAlterPropsReturn,
  IPointsVisitControl,
  IStatusVisitControl,
} from 'client/interfaces/roteirizador.interfaces';

import api from 'services/api';

const _controller = 'SetorRota';

export const getRouterData = async (
  CodEmpresa: string,
  CodSetor: string,
  CodVendedor: string,
): Promise<IRouterData[]> => {
  const { data } = await api.get(`/${_controller}/rota`, { params: { CodEmpresa, CodSetor, CodVendedor } });
  return data;
};

export const getRouterCompData = async (CodEmpresa: string, CodSetor: string): Promise<IRouterData[]> => {
  const { data } = await api.get(`/Setores/GetClientesSemSetorRota/${CodEmpresa}/${CodSetor}`);
  return data;
};

export const processRouterData = async (toProcessData: IToProcessData): Promise<IProcessedDataReturn> => {
  const { data } = await api.post(`/${_controller}/ExecutaRoteiro`, toProcessData);
  return data;
};

export const getVisitControlPointsData = async (
  CodEmpresa: string,
  CodVendedor: string,
  CodSuperv: string,
  CodUsuario: string,
): Promise<any> => {
  try {
    const { data } = await api.get(`/RunSql/controleVisitas`, {
      params: { CodEmpresa, CodVendedor, CodSuperv, CodUsuario },
    });
    return data;
  } catch (error) {
    return [];
  }
};

export const getVisitControlStatusData = async (
  CodEmpresa: string,
  CodVendedor: string,
  CodSuperv: string,
): Promise<IStatusVisitControl[]> => {
  const { data } = await api.get(`/RunSql/controleVisitasTotais`, { params: { CodEmpresa, CodVendedor, CodSuperv } });
  return data;
};

export const getVisitAnalisysData = async (CodEmpresa: number): Promise<any[]> => {
  const { data } = await api.get(`/RaioGps`, { params: { CodEmpresa } });
  return data;
};

export const getVisitAnalisysByRole = async (codEmpresa: number, codUsuario: number): Promise<any[]> => {
  const { data } = await api.get(`/RaioGps/ByRole/${codEmpresa}/${codUsuario}`);
  return data;
};

export const postCliVisitaBloqueio = async (idRota: string): Promise<void> => {
  const { data } = await api.post(`/SetorRota/bloqRotaDiaCliente?idRota=${idRota}`);
  return data;
};

export const postAlterRcaProxVisita = async (dataToAlter: dataToAlterProps): Promise<dataToAlterPropsReturn> => {
  const { data } = await api.post(`/v2/SetorRota/AlteraDataVisita`, dataToAlter);
  return data;
};
