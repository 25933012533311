import { useEffect, useState } from 'react';
import * as S from './styles';
import { useTitlePageStore } from 'storesZustand/titlePageStore';
import { useParams } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { getRelatorio, postPlayRelatorio } from 'client/api/relatorios';
import { InputText } from 'primereact/inputtext';
import VmButton from 'components/VmButton/VmButton';
import { useUser } from 'client/hooks';
import { toast } from 'react-toastify';
import { Buffer } from 'buffer';
import { FaPlay, FaPrint } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { AiFillFileExcel } from 'react-icons/ai';
import handleExportJsonToSheet from 'utils/exportJsonToSheet';

type ReportParamProps = {
  codrel: number;
  ordem: number;
  obrigatorio: string;
  nome_param: string;
  legenda: string;
  componente: string;
  valor_padrao: string;
  itens: string;
  consulta: string;
  busca: string;
};

type ReportProps = {
  codrel: number;
  descricao: string;
  script: string;
  layout: string;
  layout_p: string;
  ativo: string;
  tipo: string;
  exibir_fv: string;
  parametros: ReportParamProps[];
};

export default function Relatorios7000Player() {
  const { setTitlePage } = useTitlePageStore();

  const { data: user } = useUser();

  const { id } = useParams();

  const [report, setReport] = useState<ReportProps | null>(null);
  const [tableData, setTableData] = useState<any[] | null>([]);
  const [formData, setFormData] = useState<any>(null);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [reportInputs, setReportInputs] = useState([]);

  useEffect(() => {
    if (id && user) {
      if (formData) {
        setFormData(null);
      }
      handleGetReportInfo();
    }
  }, [id, user]);

  async function handleGetReportInfo() {
    const relatorio = await getRelatorio(id);
    setReport(relatorio.data);

    const newFormData = {};
    (relatorio.data.parametros ? relatorio.data.parametros : [])?.map((param: ReportParamProps) => {
      newFormData[param.nome_param] = param.valor_padrao ?? '';
    });

    if (user.codEmpresa != 2072021 && newFormData['PCODEMPRESA'] == '') {
      newFormData['PCODEMPRESA'] = user.codEmpresa.toString();
    }
    const reportInputList = relatorio.data.parametros?.map((param: ReportParamProps) => handleParamInputRender(param, relatorio.data.codrel));
    setReportInputs(reportInputList);
    setFormData(newFormData);
  }

  useEffect(() => {
    if (report) {
      setTitlePage(`${report.codrel} - ${report.descricao}`);
    }
  }, [report]);

  const handleParamInputRender = (param: ReportParamProps, codrel: number) => {
    if (user.codEmpresa != 2072021 && param.nome_param == 'PCODEMPRESA') {
      param.valor_padrao = user.codEmpresa.toString();
    }
    return (
      <S.ReportPlayerInputBox key={`${codrel}.${param.nome_param}`}>
        <label>
          {param.obrigatorio == 'S' ? '*' : ''}
          {param.legenda ?? param.nome_param}
        </label>
        <S.ReportPlayerInput
          defaultValue={param.valor_padrao ?? ''}
          type="text"
          onChange={(e) => {
            setFormData((prev: any) => {
              prev[param.nome_param] = e.target.value;
              return {
                ...prev,
              };
            });
          }}
          id={param.nome_param}
          name={param.nome_param}
          key={param.nome_param}
          disabled={user.codEmpresa != 2072021 && param.nome_param == 'PCODEMPRESA'}
        />
      </S.ReportPlayerInputBox>
    );
  };

  const isAnyMandatoryParamEmpty = () => {
    const mandatoryFields: ReportParamProps[] = report.parametros?.filter(
      (p: ReportParamProps) => p.obrigatorio == 'S',
    );
    if (mandatoryFields.length == 0) {
      return false;
    } else {
      let isAnyMandatoryFieldEmpty = false;
      for (let i = 0; i < mandatoryFields.length; i++) {
        if (!formData[mandatoryFields[i].nome_param] || formData[mandatoryFields[i].nome_param] == '') {
          isAnyMandatoryFieldEmpty = true;
        }
      }
      return isAnyMandatoryFieldEmpty;
    }
  };

  const handleParamsCheck = () => {
    const reportDataToExecIndexes = Object.keys(formData);
    const reportDataToExec = [];
    for (let i = 0; i < reportDataToExecIndexes.length; i++) {
      reportDataToExec.push({
        nome_param: reportDataToExecIndexes[i],
        valor_parametro:
          formData[reportDataToExecIndexes[i]] != ''
            ? `'${formData[reportDataToExecIndexes[i]]}'`
            : formData[reportDataToExecIndexes[i]],
      });
    }
    reportDataToExec.push({
      nome_param: 'codempresa',
      valor_parametro: user.codEmpresa.toString() ?? '0',
    });

    return reportDataToExec;
  };

  async function handleExecRel() {
    if (isAnyMandatoryParamEmpty()) {
      toast.warn('Preencha os campos obrigatórios *');
      return;
    }
    setIsLoadingData(true);
    const reportDataToExec = handleParamsCheck();
    const reportData = await postPlayRelatorio(id, reportDataToExec);
    if (reportData.succeeded && reportData.data) {
      setTableData(reportData.data ?? []);
    } else {
      if (reportData.message || reportData.errors) {
        toast.error(`${reportData.message}. ${reportData.errors}`);
      } else {
        toast.warn(`Nenhum registro encontrado com os filtros informados`);
      }
    }

    setIsLoadingData(false);
  }

  async function handleViewOrEditRel(reportMode: 'designer' | 'viewer') {
    if (isAnyMandatoryParamEmpty()) {
      toast.warn('Preencha os campos obrigatórios *');
      return;
    }
    const userTokenHashString = localStorage.getItem('vmais_token')
      ? localStorage.getItem('vmais_token').replace('"', '')
      : '';
    const userTokenBearer = `Bearer ${userTokenHashString}`;
    const b64TokenData = Buffer.from(userTokenBearer, 'utf-8').toString('base64');

    const reportDataToView = handleParamsCheck();
    const paramsToken = Buffer.from(JSON.stringify(reportDataToView), 'utf-8').toString('base64');
    if (reportMode == 'designer') {
      Swal.fire({
        icon: 'question',
        title: 'Qual o layout?',
        text: 'Escolha a versão do layout a editar:',
        showCloseButton: true,
        showConfirmButton: true,
        confirmButtonText: 'G - Desktop',
        showCancelButton: true,
        cancelButtonText: 'P - Mobile',
      }).then((res) => {
        if (res.isConfirmed) {
          window.open(
            `${process.env.REACT_APP_REPORT_BASE_URL}/designer?rel=${report.codrel}&t=${b64TokenData}&lft=layout&pl=${paramsToken}`,
          );
        } else if (res.isDismissed) {
          window.open(
            `${process.env.REACT_APP_REPORT_BASE_URL}/designer?rel=${report.codrel}&t=${b64TokenData}&lft=layout_p&pl=${paramsToken}`,
          );
        }
      });
    } else {
      window.open(
        `${process.env.REACT_APP_REPORT_BASE_URL}/${reportMode}?rel=${report.codrel}&t=${b64TokenData}&lft=layout&pl=${paramsToken}`,
      );
    }
  }

  const handleExportReportDataToSheet = () => {
    handleExportJsonToSheet(tableData, `${report.codrel}_${report.descricao}_${new Date().toLocaleDateString()}`);
  };

  return (
    <S.ReportMainBox>
      <S.ReportHeaderBox>
        {reportInputs && (
          <S.ReportPlayerFormInputsBox>
            {/* {report &&
            report.parametros &&
            report.parametros?.map((param: ReportParamProps) => handleParamInputRender(param))} */}
            {reportInputs}
          </S.ReportPlayerFormInputsBox>
        )}

        <S.ReportPlayerFormButtonsBox>
          <VmButton
            options={{
              label: 'Apurar',
              style: 'btn-success',
              icon: <FaPlay />,
              iconPosition: 'left',
              loading: isLoadingData,
            }}
            onClick={() => {
              if (!isLoadingData) {
                handleExecRel();
              }
            }}
          />
          {report && (
            <VmButton
              options={{
                label: 'Imprimir',
                style: report.layout == '' ? 'btn-gray' : 'btn-info',
                icon: <FaPrint />,
                iconPosition: 'left',
                tooltip: {
                  position: `top`,
                  show: report.layout == '',
                  text: `Sem layout para impressão`,
                },
              }}
              onClick={() => {
                report.layout != '' && handleViewOrEditRel('viewer');
              }}
            />
          )}
          <VmButton
            options={{
              label: 'Exportar',
              style: 'btn-success',
              icon: <AiFillFileExcel />,
              iconPosition: 'left',
            }}
            onClick={() => {
              if (tableData.length > 0) {
                handleExportReportDataToSheet();
              } else {
                toast.dismiss();
                toast.warn('Nenhum dado a ser exportado');
              }
            }}
          />
        </S.ReportPlayerFormButtonsBox>
      </S.ReportHeaderBox>

      <S.ReportBodyBox>
        <DataTable
          value={tableData}
          rows={100}
          rowHover
          stripedRows
          emptyMessage={'Nenhum dado a ser exibido...'}
          paginator={tableData.length > 10}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        >
          {Object.keys(tableData[0] ?? []).map((key: string) => (
            <Column field={key} header={key.toUpperCase()} sortable key={key} />
          ))}
        </DataTable>
      </S.ReportBodyBox>
    </S.ReportMainBox>
  );
}
