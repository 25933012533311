import styled from 'styled-components';

interface IMapProps {
  showMenu?: boolean;
  codColorSetor?: string;
}

export const Content = styled.section<IMapProps>`
  background-color: #fafafa;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  opacity: ${(props) => (props.showMenu ? 'none' : '0')};
  transition: ${(props) => (!props.showMenu ? 'opacity 0.4s ease' : '')};
  @media (max-width: 992px) {
    height: 90%;
    border-radius: none;
  }
`;

export const MapSideBarMainBox = styled.div<IMapProps>`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;

  background-color: #fafafa;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  height: 82vh;
  transition: all 0.4s ease;

  margin: 2vh;
  border-radius: 10px;
  width: ${(props) => (props.showMenu ? '30vw' : '2vw')};

  @media (max-width: 1036px) {
    z-index: 99;
    width: 100%;
    height: 80vh;
    top: ${(props) => (props.showMenu ? '30px' : 'calc(100% - 45px)')};
    border-radius: none;
    margin: 0;
    right: 0;
  }
`;

export const SideBarHeaderClosed = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 50%;
  writing-mode: vertical-rl;
  font-weight: 600;
  font-size: 1rem;
  text-align: center;

  @media (max-width: 992px) {
    writing-mode: horizontal-tb;
    top: 26%;
    z-index: 99;
  }
`;

export const SideBarHeader = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  font-weight: 600;
  position: static;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 0 auto;
  margin-bottom: 20px;
  padding: 5px;
  overflow: hidden;
`;

export const Input = styled.input`
  width: 100%;
  background-color: #fafafa;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 25px;
  padding: 5px;
  padding-left: 10px;

  flex-direction: column;
  transition: all 0.4s ease;
  border: none;
  outline: none;
  height: 2rem;
`;

export const SetorTableIconTd = styled.div`
  font-size: 1.75rem;
  cursor: pointer;
  padding: 1 !important;
  display: flex;
  gap: 5px;
`;

export const SetorCodColorBackground = styled.div<IMapProps>`
  color: var(--white);
  text-align: center;
  padding: 5px;
  border-radius: 5px;
  font-weight: bolder;
  font-size: 0.9rem;
  cursor: pointer;
  display: flex;
  justify-content: left;
  height: 65px;
  opacity: 0.8;
  margin-left: -7px;
  background-color: ${(props) => (props.codColorSetor ? '#' + props.codColorSetor : 'var(--dark-900)')};

  @media (max-width: 992px) {
    height: 1px;
    width: 100%;
    padding: 3px;
    border-radius: 0px 0px 10px 10px;
  }
`;

export const MapSideBarOpenBtn = styled.div<IMapProps>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 100%;
  width: 30px;
  height: 50px;
  background-color: var(--red);
  z-index: 999999;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;

  svg {
    color: var(--white);
    transition: all 1.2s ease;
    transform: ${(props) => (!props.showMenu ? 'rotate(180deg)' : 'none')};
  }

  @media (max-width: 992px) {
    top: -30px;
    right: 50%;
    width: 50px;
    height: 30px;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    transform: translateX(45%);
    margin: 0 auto;

    svg {
      color: var(--white);
      transition: all 1.2s ease;
      transform: ${(props) => (!props.showMenu ? 'rotate(-90deg)' : 'rotate(90deg)')};
    }
  }
`;

export const SideBarHeaderFilterBox = styled.div`
  width: 100%;
  position: relative;
  background-color: #fafafa;
  min-width: 50px;
  border-radius: 2.5px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  min-height: 2.5rem;
  padding: 5px;
  cursor: pointer;

  .filterTitle {
  }

  .filterStatus {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .filterStatus svg {
    color: #095dd1;
    font-size: 1.5rem;
  }

  .filterOptions {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 0px;
    opacity: 0;
  }

  .filterOptionsOpen {
    height: auto;
    overflow: visible;
    opacity: 1;
    transition: opacity 0.7s ease;
  }

  .filterOption {
    display: flex;
    flex-direction: column;
    gap: 2.5px;
  }

  .filterOption select {
    background-color: #fafafa;
    color: #606060;
    outline: none;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    width: 100%;
    padding: 10px 5px;
    font-size: 1.15rem;
  }
`;

export const AnaliseHeaderTextInputBox = styled.div`
  position: sticky;
  top: 0px;
  background-color: #ffffff;
  width: 100%;
  margin-bottom: 20px;
  padding: 25px 5px 0px 5px;
  z-index: 9;
`;

export const AnaliseHeaderTextInput = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
  border: 1px solid #60606050;
  border-radius: 2.5px;
  padding: 0 5px;
  position: relative;
  background-color: #fff;
  color: #606060;

  input {
    color: #606060;
    border: none;
    outline: none;
    padding: 5px;
  }

  label {
    position: absolute;
    bottom: 100%;
    font-size: 0.9rem;
  }
`;

export const HeaderTextInput = styled.input`
  color: #606060;
  border: none;
  outline: none;
  padding: 5px;
`;
