import { useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { InputSwitch } from 'primereact/inputswitch';
import { useMemo } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { BsCheckLg, BsXLg } from 'react-icons/bs';
import { toast } from 'react-toastify';

import { duplicateItems, duplicateRestricoes, saveForm, updateForm } from 'client/api';
import { FormularioCab } from 'client/interfaces';
import InputData from 'components/InputData';

import styles from './NewFormDialog.module.scss';
import * as S from './styles';
import useMediaQuery from 'hooks/useMediaQuery';
import UseSelectEmpresa from 'hooks/UseSelectEmpresa/selectEmpresas';
import VmButton from 'components/VmButton/VmButton';

interface NewFormDialogProps {
  form: FormularioCab | null;
  onClose: () => void;
  visible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  clonedItem: number | null;
  codEmpresa?: number;
}

const NewFormDialog = ({ form, visible, setIsVisible, onClose, clonedItem, codEmpresa }: NewFormDialogProps) => {
  const {
    handleSubmit,
    register,
    control,
    watch,
    formState: { errors, dirtyFields },
  } = useForm<FormularioCab>({ defaultValues: form ?? undefined });
  const queryClient = useQueryClient();
  const isWebScreen = useMediaQuery('(min-width: 1060px)');
  const { selectedEmpresa } = UseSelectEmpresa();

  const onSubmit: SubmitHandler<FormularioCab> = async (data) => {
    data.codEmpresa = codEmpresa ?? selectedEmpresa.codEmpresa;
    try {
      if (form?.nroform) {
        await updateForm(data).then(() => {
          toast.success('Formulário atualizado com sucesso!');
        });
      } else {
        if (clonedItem == null) {
          await saveForm(data);
          toast.success('Formulário salvo com sucesso!');
        } else {
          confirmDialog({
            message: 'Deseja replicar também as restrições?',
            header: 'Replicar Restrições',
            icon: 'pi pi-question-circle',
            accept: async () => {
              const nro = await saveForm(data);
              await duplicateItems(clonedItem, nro, selectedEmpresa.codEmpresa);
              await duplicateRestricoes(clonedItem, nro, selectedEmpresa.codEmpresa);
              toast.success('Formulário clonado com sucesso!');
            },
            reject: async () => {
              const nro = await saveForm(data);
              await duplicateItems(clonedItem, nro, selectedEmpresa.codEmpresa);
              toast.success('Formulário clonado com sucesso!');
            },
          });
        }
      }
      setIsVisible(false);
      queryClient.invalidateQueries(['forms/cabecalho']);
    } catch (e) {
      setIsVisible(false);
      toast.error('Falha ao salvar formulário');
    }
  };

  const foraDeVigencia = useMemo(
    () => watch().dtfimvigencia != null && new Date(watch().dtfimvigencia) < new Date(),
    [watch().dtfimvigencia],
  );

  const header = useMemo(() => {
    if (form?.nroform) return `Editar Formulário ${form.nroform}`;
    if (clonedItem) return `Clonar Formulário ${clonedItem}`;
    return 'Novo Formulário';
  }, [form]);

  return (
    <Dialog header={header} visible={visible} style={{ width: isWebScreen ? '30vw' : '90vw' }} onHide={onClose}>
      <form onSubmit={handleSubmit(onSubmit)} className="form w-100">
        <div className="fv-row mb-10">
          <label className="form-label fs-6 fw-bolder text-dark">* Descrição</label>
          <input
            {...register('descricao', { required: 'Obrigatório' })}
            placeholder="Descrição"
            className={clsx('form-control form-control-lg form-control-solid', {
              'is-invalid': (dirtyFields.descricao ?? false) && errors.descricao,
            })}
            autoFocus
            maxLength={300}
            type="descricao"
            name="descricao"
          />
          {errors.descricao != null && (
            <div className="fv-plugins-message-container invalid-feedback">
              <span role="alert">{errors.descricao.message}</span>
            </div>
          )}
        </div>

        <div className={`${styles.container} fv-row mb-8`}>
          <div className={`${styles.flex1} me-8`}>
            <label className="form-label fs-6 fw-bolder text-dark">* Cód. Filial</label>
            <input
              {...register('codfilial', { required: 'Obrigatório' })}
              placeholder="Cód. Filial"
              className={clsx('form-control form-control-lg form-control-solid', {
                'is-invalid': (dirtyFields.codfilial ?? false) && errors.codfilial,
              })}
              autoFocus
              maxLength={6}
              type="codfilial"
              name="codfilial"
            />
            {errors.codfilial != null && (
              <div className="fv-plugins-message-container invalid-feedback">
                <span role="alert">{errors.codfilial.message}</span>
              </div>
            )}
          </div>
          <div className={styles.flex1}>
            <div>
              <label className="form-label fs-6 fw-bolder text-dark">Obrigatório</label>
            </div>
            <Controller
              control={control}
              name="obrigatorio"
              render={({ field: { onChange, value } }) => <InputSwitch checked={value} onChange={onChange} />}
            />
          </div>
        </div>

        <S.RowInputsData>
          <S.BoxInput>
            <label className="form-label fs-6 fw-bolder text-dark">* Data Início</label>

            <Controller
              control={control}
              name="dtiniciovigencia"
              rules={{ required: 'Obrigatório' }}
              render={({ field: { onChange, value }, fieldState }) => (
                <>
                  <InputData
                    value={value ? new Date(value) : null}
                    dateFormat="dd/mm/yy"
                    placeholder="dd/mm/yyyy"
                    mask="99/99/9999"
                    onChange={(e) => onChange((e.value as Date).toISOString())}
                  />
                  {errors.dtiniciovigencia != null && (
                    <div className="fv-plugins-message-container invalid-feedback">
                      <span role="alert">{fieldState.error?.message}</span>
                    </div>
                  )}
                </>
              )}
            />
          </S.BoxInput>

          <S.BoxInput>
            <label className="form-label fs-6 fw-bolder text-dark">* Data Fim</label>

            <Controller
              control={control}
              name="dtfimvigencia"
              rules={{ required: 'Obrigatório' }}
              render={({ field: { onChange, value }, fieldState }) => (
                <>
                  <InputData
                    value={value ? new Date(value) : null}
                    dateFormat="dd/mm/yy"
                    placeholder="dd/mm/yyyy"
                    mask="99/99/9999"
                    onChange={(e) => onChange((e.value as Date).toISOString())}
                  />
                  {errors.dtfimvigencia != null && (
                    <div className="fv-plugins-message-container invalid-feedback">
                      <span role="alert">{fieldState.error?.message}</span>
                    </div>
                  )}
                </>
              )}
            />
          </S.BoxInput>
        </S.RowInputsData>

        <div className="fv-row mb-8">
          <div>
            <label className="form-label fs-6 fw-bolder text-dark">Ativo</label>
          </div>
          <Controller
            control={control}
            name="ativo"
            render={({ field: { onChange, value } }) => (
              <InputSwitch
                disabled={foraDeVigencia}
                checked={value && !foraDeVigencia}
                onChange={onChange}
                tooltip="Fora do período de vigência"
                tooltipOptions={{
                  disabled: !foraDeVigencia,
                  showOnDisabled: true,
                }}
              />
            )}
          />
        </div>

        <S.RowItens>
          <VmButton
            type="submit"
            options={{
              icon: <BsCheckLg />,
              label: 'Salvar',
              style: 'btn-success',
              width: '120px',
              iconPosition: 'left',
              size: 'btn-md',
            }}
          />
          <div style={{ width: '10px' }} />
          <VmButton
            options={{
              icon: <BsXLg />,
              label: 'Cancelar',
              style: 'btn-info',
              width: '120px',
              iconPosition: 'left',
              size: 'btn-md',
            }}
            onClick={onClose}
          />
        </S.RowItens>
      </form>

      <ConfirmDialog />
    </Dialog>
  );
};

export default NewFormDialog;
