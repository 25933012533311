import { useEffect, useState } from 'react';

import * as S from './styles';

import { Dropdown } from 'primereact/dropdown';
import { getEmpresas, getVisitAnalisysByRole } from 'client/api';
import ActionBar from '../actionBar';
import UseSelectEmpresa from 'hooks/UseSelectEmpresa/selectEmpresas';
import VmButton from 'components/VmButton/VmButton';
import { MdRefresh } from 'react-icons/md';
import { UseUsers } from 'hooks/useUsers';
import { useAppSelector } from 'store';
import { userSelector } from 'modules/auth/reducer/auth';
import { UseByUserFiliais } from 'hooks/useFiliais/useByRoleFiliais';
import { UseCompaniesSelect } from 'hooks/useCompanies/useCompaniesSelect';

export default function AnaliseHeader({ setSearchResult, selectedCli, setSelectedCli }: any) {
  const user = useAppSelector(userSelector);

  const [data, setData] = useState([]);
  const [selectedFilial, setSelectedFilial] = useState<any>([]);
  const [selectedUserCompany, setSelectedUserCompany] = useState(user?.codUsuario);
  const [selectedCompany, setSelectedCompany] = useState(user?.codEmpresa);

  const [selectedStatus, setSelectedStatus] = useState({ name: 'A - Análise ', code: 'A' });
  const [startDate, setStartDate] = useState(
    new Date(Date.now() - 86400000).toLocaleDateString().split('/').reverse().join('-'),
  );
  const [endDate, setEndDate] = useState(new Date().toLocaleDateString().split('/').reverse().join('-'));

  const statusList = [
    { name: 'T - Todos ', code: 'T' },
    { name: 'A - Análise ', code: 'A' },
    { name: 'L - Liberado ', code: 'L' },
    { name: 'R - Rejeitado ', code: 'R' },
  ];

  const { optionsUsersCompany, refetchUsersCompany } = UseUsers(selectedCompany);
  const { optionsCompanies } = UseCompaniesSelect();
  const { optionsFiliais, refetchFiliais } = UseByUserFiliais({
    codEmpresa: selectedCompany,
    codUser: selectedUserCompany,
  });

  useEffect(() => {
    if (selectedCompany && selectedUserCompany) {
      handleGetMainData();
    }
  }, [selectedCompany, selectedUserCompany]);

  async function handleGetMainData() {
    let dataList = await getVisitAnalisysByRole(selectedCompany, selectedUserCompany);
    setSearchResult(dataList ?? []);
    setData(dataList ?? []);
    handleFilter();
  }

  useEffect(() => {
    refetchUsersCompany();
  }, [selectedCompany]);

  useEffect(() => {
    refetchFiliais();
  }, [selectedCompany, selectedUserCompany]);

  useEffect(() => {
    setSelectedUserCompany(user?.codUsuario);
  }, []);

  const [textFilter, setTextFilter] = useState('');

  const handleFilter = () => {
    let toFilterData = data;

    if (selectedStatus.code === 'T') {
      toFilterData = data;
    } else {
      toFilterData = data.filter((dataList: any) => dataList.status === selectedStatus.code);
    }

    if (textFilter != '') {
      const textStartFiltered = toFilterData.filter(
        (dataList: any) =>
          dataList.codcli == +textFilter ||
          dataList.nomeCliente.toUpperCase().includes(textFilter.toUpperCase()) ||
          dataList.codusur == +textFilter ||
          dataList.nomeVendedor.toUpperCase().includes(textFilter.toUpperCase()),
      );
      toFilterData = textStartFiltered;
    }

    if (startDate) {
      const dtStartFiltered = toFilterData.filter((dataList: any) => dataList.dtinclusao.slice(0, 10) >= startDate);
      toFilterData = dtStartFiltered;
    }

    if (endDate) {
      const dtEndFiltered = toFilterData.filter((dataList: any) => dataList.dtinclusao.slice(0, 10) <= endDate);
      toFilterData = dtEndFiltered;
    }

    if (selectedFilial) {
      const filialFiltered = toFilterData.filter((dataList: any) => +dataList.codfilial == +selectedFilial);
      toFilterData = filialFiltered;
    }

    setSearchResult(toFilterData);
  };

  useEffect(() => {
    handleFilter();
  }, [data, selectedStatus, textFilter, startDate, endDate, selectedFilial]);

  return (
    <>
      <S.AnaliseHeaderMainBox>
        <S.AnaliseHeaderFormBox>
          {user && user.codEmpresa === 2072021 && (
            <span className="p-float-label">
              <Dropdown
                value={selectedCompany ? selectedCompany : user?.codEmpresa}
                onChange={(e) => setSelectedCompany(e.value)}
                options={optionsCompanies}
                filter
                className="md:w-10rem"
              />
              <label>Empresa</label>
            </span>
          )}

          {user && user.codEmpresa === 2072021 && (
            <span className="p-float-label">
              <Dropdown
                value={selectedUserCompany ? selectedUserCompany : user?.codUsuario}
                onChange={(e) => setSelectedUserCompany(e.value)}
                options={optionsUsersCompany}
                optionLabel="label"
                className="md:w-10rem"
                filter
                filterBy='label'
              />
              <label>Usuário</label>
            </span>
          )}

          <span className="p-float-label">
            <Dropdown
              value={selectedFilial}
              onChange={(e) => setSelectedFilial(e.value)}
              options={optionsFiliais}
              style={{ width: '10rem' }}
              emptyMessage={'Nenhuma filial'}
            />
            <label>Filial</label>
          </span>

          <span className="p-float-label">
            <Dropdown
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.value)}
              options={statusList}
              optionLabel="name"
              className="md:w-10rem"
            />
            <label>Posição</label>
          </span>

          <S.AnaliseHeaderDateInput>
            <input
              type={'date'}
              id={'dateStart'}
              defaultValue={startDate}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setStartDate(e.target.value);
              }}
            />
            <label>Data Inicial</label>
          </S.AnaliseHeaderDateInput>

          <S.AnaliseHeaderDateInput>
            <input
              type={'date'}
              id={'dateEnd'}
              defaultValue={endDate}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEndDate(e.target.value)}
            />
            <label>Data Final</label>
          </S.AnaliseHeaderDateInput>
          <VmButton
            options={{
              icon: <MdRefresh />,
              iconPosition: 'left',
              style: 'btn-light',
            }}
            onClick={() => handleGetMainData()}
            id={'reloadAnalisesButton'}
          />
        </S.AnaliseHeaderFormBox>
      </S.AnaliseHeaderMainBox>
      {selectedCli != null && selectedCli.status == 'A' && (
        <ActionBar selectedCli={selectedCli} setSelectedCli={setSelectedCli} handleGetMainData={handleGetMainData} />
      )}
    </>
  );
}
