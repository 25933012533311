import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { confirmPopup, ConfirmPopup } from 'primereact/confirmpopup';
import { DataTable } from 'primereact/datatable';
import { Menu } from 'primereact/menu';
import { classNames } from 'primereact/utils';
import { useCallback, useEffect, useRef, useState } from 'react';
import { BsPlusLg } from 'react-icons/bs';
import { toast } from 'react-toastify';

import { deleteCabecalho } from 'client/api';
import { FormularioCab } from 'client/interfaces';
import ButtonComponent from 'components/Button';
import { BlockTableTemplate, booleanBodyTemplate, styleHeaderCenter } from 'components/datatable';
import { formatDateTime } from 'utils/datetime';

import FormularioItemTable from './FormularioItemTable';
import FormularioRestricoesTable from './FormularioRestricoesTable';
import NewFormDialog from './NewFormDialog';
import { IconTable, RowItens } from './styles';

import * as S from './styles';
import { useQueryClient } from '@tanstack/react-query';
import { Toolbar } from 'primereact/toolbar';

import { useAppSelector } from 'store';
import { userSelector } from 'modules/auth/reducer/auth';
import { useForm } from 'react-hook-form';
import { FilterByCompany } from 'components/FilterByCompany';
import { FilterByStatus } from 'components/FilterByStatus';
import { FilterByUsersCompany } from 'components/FilterByUsersCompany';
import { FilterByFilialCompany } from 'components/FilterByFillialCompany';
import { useCabecalhos } from 'client/hooks';
import { BlockUI } from 'primereact/blockui';

const ativoBodyTemplate = (rowData: FormularioCab) => {
  const ativo = rowData.ativo;
  return (
    <IconTable>
      <i
        className={classNames('pi', {
          'true-icon pi-check-circle text-success': ativo,
          'false-icon pi-times-circle text-danger': !ativo,
        })}
      />
    </IconTable>
  );
};

const FormularioCabTable = () => {
  const user = useAppSelector(userSelector);

  const { control, watch, setValue } = useForm({
    defaultValues: {
      codCompany: user?.codEmpresa,
      codUserByCompany: user?.codUsuario,
      codFilial: '',
      codStatus: 'A',
    },
  });

  const values = watch();

  const { data: cabecalhos, isLoading, refetch } = useCabecalhos(Number(values.codCompany));

  const [selectedItem, setSelectedItem] = useState<FormularioCab | null>(null);
  const [isVisible, setIsVisible] = useState(false);
  const [formNro, setFormNro] = useState<number | null>(null);
  const [isItemsVisible, setIsItemsVisible] = useState(false);
  const [isRestrictionsVisible, setIsRestrictionsVisible] = useState(false);

  const menu = useRef<Menu>(null);

  const queryClient = useQueryClient();

  const onClose = () => {
    setSelectedItem(null);
    setFormNro(null);
    setIsVisible(false);
  };

  const handleEdit = (rowData: FormularioCab) => {
    setSelectedItem(rowData);
    setIsVisible(true);
  };

  const removeItem = async (data: FormularioCab) => {
    try {
      await deleteCabecalho(data.nroform);
      queryClient.invalidateQueries({
        queryKey: ['forms/cabecalho'],
      });
      toast.success('Cabeçalho excluído com sucesso!');
    } catch {
      toast.error('Erro ao excluir cabeçalho');
    }
  };

  const confirmDeleteItem = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, data: FormularioCab) => {
    confirmPopup({
      target: event.currentTarget,
      message: 'Tem certeza que deseja excluir este formulário? Esta ação irá excluir seus itens e restrições!',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: async () => await removeItem(data),
    });
  };

  const handleClone = () => {
    if (selectedItem) {
      setSelectedItem({ ...selectedItem, nroform: 0 });
    }
    setIsVisible(true);
  };

  const menuItems = [
    {
      label: 'Items',
      icon: 'pi pi-bars',
      command: () => setIsItemsVisible(true),
    },
    {
      label: 'Clonar',
      icon: 'pi pi-copy',
      command: () => handleClone(),
    },
    {
      label: 'Restrições',
      icon: 'pi pi-lock',
      command: () => setIsRestrictionsVisible(true),
    },
  ];

  const onCloseItem = () => {
    setFormNro(null);
    setIsItemsVisible(false);
    setIsRestrictionsVisible(false);
  };

  const actionBodyTemplate = (rowData: FormularioCab) => {
    return (
      <RowItens>
        <Button
          icon="pi pi-pencil"
          tooltip="Editar"
          onClick={() => handleEdit(rowData)}
          className="p-button-rounded p-button-text p-button-plain me-2"
        />
        <Button
          icon="pi pi-trash"
          tooltip={
            rowData.temRespostas
              ? 'Não é possível excluir um fomulário que já contenha respostas. Você pode apenas desativar ou alterar o período de vigência.'
              : 'Excluir'
          }
          tooltipOptions={{ showOnDisabled: true }}
          disabled={rowData.temRespostas}
          onClick={(e) => confirmDeleteItem(e, rowData)}
          className="p-button-rounded p-button-text p-button-plain me-2"
        />
        <Button
          icon="pi pi-ellipsis-h"
          tooltip="Mais"
          onClick={(e) => {
            setSelectedItem(rowData);
            setFormNro(rowData.nroform);
            menu.current?.toggle(e);
          }}
          className="p-button-rounded p-button-text p-button-plain"
        />
      </RowItens>
    );
  };

  const createNew = useCallback(() => {
    setSelectedItem(null);
    setIsVisible(true);
  }, []);

  const filterByFilial = cabecalhos?.filter((formulario) => {
    return String(values.codFilial) === String(formulario.codfilial);
  });

  const filterByStatus = filterByFilial?.filter((formulario) => {
    return values.codStatus === 'S'
      ? formulario.ativo === true
      : values.codStatus === 'N'
      ? formulario.ativo === false
      : formulario;
  });

  useEffect(() => {
    setValue('codCompany', user?.codEmpresa);
    setValue('codUserByCompany', user?.codUsuario);
  }, []);

  useEffect(() => {
    setValue('codFilial', '');
    setValue('codUserByCompany', 0);
    setValue('codStatus', 'S')
  }, [values.codCompany]);

  const header = (
    <S.HeaderBox>
      <ButtonComponent icon={<BsPlusLg />} text="Adicionar" onClick={createNew} />
      {user.codEmpresa === 2072021 && (
        <>
          <S.InputBox>
            <label>Empresa</label>
            <FilterByCompany control={control} setValue={setValue} />
          </S.InputBox>

          <S.InputBox>
            <label>Usuário</label>
            <FilterByUsersCompany control={control} setValue={setValue} codCompany={values.codCompany} />
          </S.InputBox>
        </>
      )}

      <S.InputBox>
        <label>Filial</label>
        <FilterByFilialCompany
          control={control}
          setValue={setValue}
          codCompany={values.codCompany}
          codUser={values.codUserByCompany}
        />
      </S.InputBox>

      <S.InputBox>
        <label>Ativo/Inativo</label>
        <FilterByStatus control={control} setValue={setValue} />
      </S.InputBox>
    </S.HeaderBox>
  );

  return (
    <S.ContainerTable>
      <Toolbar className="mb-4" left={header} />
      <BlockUI
        containerStyle={{ zIndex: 0 }}
        blocked={!values.codFilial || values.codFilial === '' || values.codFilial === undefined}
        template={BlockTableTemplate('Selecione uma Filial')}
      >
        <DataTable
          value={filterByStatus}
          loading={isLoading}
          paginator
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          rowsPerPageOptions={[10, 25, 50]}
          rows={10}
          emptyMessage="Nenhum dado encontrado"
          currentPageReportTemplate="Mostrando de {first} a {last} de {totalRecords} registros"
        >
          <Column header="Nº Form" field="nroform" />
          <Column header="Descrição" field="descricao" />
          <Column
            header="Dt. Início"
            field="dtiniciovigencia"
            body={(rowData: FormularioCab) => formatDateTime('dd/MM/yyyy', new Date(rowData.dtiniciovigencia))}
          />
          <Column
            header="Dt. Fim"
            field="dtfimvigencia"
            body={(rowData: FormularioCab) => formatDateTime('dd/MM/yyyy', new Date(rowData.dtfimvigencia))}
          />
          <Column header="Cód. Filial" field="codfilial" />
          <Column
            header="Obrigatório"
            field="obrigatorio"
            headerStyle={styleHeaderCenter}
            body={(rowData: FormularioCab) => booleanBodyTemplate(rowData.obrigatorio)}
          />
          <Column header="Ativo" field="ativo" headerStyle={styleHeaderCenter} body={ativoBodyTemplate} />
          <Column body={(rowData: FormularioCab) => actionBodyTemplate(rowData)} />
        </DataTable>
        <Menu model={menuItems} popup ref={menu} />
        {isVisible && (
          <NewFormDialog
            visible={isVisible}
            setIsVisible={setIsVisible}
            form={selectedItem}
            clonedItem={formNro}
            onClose={onClose}
            codEmpresa={values.codCompany}
          />
        )}
        {isItemsVisible && selectedItem != null && (
          <FormularioItemTable
            codCompany={values.codCompany}
            visible={isItemsVisible}
            cab={selectedItem}
            onClose={onCloseItem}
          />
        )}
        {isRestrictionsVisible && formNro != null && (
          <FormularioRestricoesTable
            codEmpresa={String(values.codCompany)}
            visible={isRestrictionsVisible}
            id={formNro}
            onClose={onCloseItem}
            codUsuario={String(values.codUserByCompany)}
          />
        )}
        <ConfirmPopup />
      </BlockUI>
    </S.ContainerTable>
  );
};

export default FormularioCabTable;
