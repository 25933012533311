import {
  GetKpiItemProps,
  KpiAllPeriodReturnProps,
  KpiAllReturnProps,
  KpiItemProps,
  KpiProps,
  KpiRunReturnProps,
} from 'client/interfaces';
import api from 'services/api';

const _controller = 'KPI';

export const getAllKpi = async (codEmpresa: string | number): Promise<KpiAllReturnProps> => {
  const { data } = await api.get(`${_controller}/GetAllKpi/${codEmpresa}`);
  return data;
};

export const getAllPeriodKpi = async (codEmpresa: string | number): Promise<KpiAllPeriodReturnProps> => {
  const { data } = await api.get(`${_controller}/GetPeriodoKPI/${codEmpresa}`);
  return data;
};

export const getItemKpi = async (itemBody: GetKpiItemProps): Promise<KpiItemProps[]> => {
  const { data } = await api.get(`${_controller}/GetItemsKPIByCode/${itemBody.codEmpresa}/${itemBody.nroKpi}/${itemBody.descPeriodo}`);
  return data;
};

export const postRunKpi = async (codEmpresa: string | number, nroKpi: string | number): Promise<KpiRunReturnProps> => {
  const { data } = await api.post(`${_controller}/RunKpi/${nroKpi}`);
  return data;
};

export const putEditKpi = async (kpi: KpiProps): Promise<KpiRunReturnProps> => {
  const { data } = await api.post(`${_controller}/PutKpi`, kpi);
  return data;
};

export const postNewKpi = async (kpi: KpiProps): Promise<KpiRunReturnProps> => {
  const { data } = await api.post(`${_controller}/PostKpi`, kpi);
  return data;
};

export const putItemKpi = async (kpi: KpiProps): Promise<KpiRunReturnProps> => {
  const { data } = await api.post(`${_controller}/PutItemKPI`, kpi);
  return data;
};
